<template>
    <div>
        <div class="md-layout">
            <md-card>
                <transition name="fade">
                    <div v-if="isPlanWorksDataLoading" class="data-load-progress">
                        <md-progress-bar
                                md-mode="indeterminate"
                                class="progress-bar"
                        ></md-progress-bar>
                    </div>
                </transition>
                <md-card-header class="md-card-header-icon md-card-header-green card-icon">
                    <div class="card-icon">
                        <md-icon>work</md-icon>
                    </div>
                    <h4 class="title">{{ $t('label.plans') }}</h4>
                    <div class="report-export-icon" @click="onExport">
                        <i class="fas fa-file-export" :class="{'fa-spinner': isPlanExportDataLoading}"></i>
                    </div>
                </md-card-header>
                <md-card-content>

                    <md-checkbox @change="onSelectAllChanged" :value="!isAllSelected">
                        {{ $t('labels.select_all') }}
                    </md-checkbox>
                    <div>
                        <md-table

                                :value="queriedData"
                                :md-sort.sync="currentSort"
                                :md-sort-order.sync="currentSortOrder"
                                :md-sort-fn="customSort"
                                class="paginated-table table-striped table-hover"
                                @md-selected="onSelect"
                                :md-selected-value.sync="selectedRows"
                        >
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">{{ $t('label.per_page') }}</label>
                                    <md-select v-model="pagination.perPage" name="pages" id="pages">
                                        <md-option
                                                v-for="item in pagination.perPageOptions"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                        >
                                            {{ item }}
                                        </md-option>
                                    </md-select>
                                </md-field>
                                <md-field>
                                    <md-input
                                            type="search"
                                            class="mb-3"
                                            clearable
                                            style="width: 200px"
                                            :placeholder="$t('label.search_records')"
                                            v-model="searchQuery"
                                    ></md-input>
                                </md-field>

                            </md-table-toolbar>
                            <md-table-row
                                    slot="md-table-row"
                                    slot-scope="{item, index:rownum}"
                                    :md-selectable="'multiple'"
                                    :selected="true"
                                    :md-indeterminate="true"

                            >
                                <template
                                        v-for="(field, index) of workList.headers">
                                    <md-table-cell
                                            :key="field+index+rownum"
                                            :md-label="$t(`plans.${field}`)"
                                            :md-sort-by="field"
                                            v-if="headers.includes(field)"
                                            :md-numeric="['year', 'quantity', 'unit_cost', 'total_cost'].includes(field)"
                                            :title="['element', 'work_type', 'work_name'].includes(field) ? $t(`plan.${field}_${item[index]}`) :
                                        item[index]">

                                        <div class="cut-text">{{
                                            ['element', 'work_type', 'work_name'].includes(field) ? $t(`plan.${field}_${item[index]}`) : item[index]
                                            }}
                                        </div>
                                    </md-table-cell>
                                </template>

                            </md-table-row>
                        </md-table>
                    </div>
                    <div class="footer-table md-table">
                        <table>
                            <tfoot>
                            <tr>
                                <th
                                        v-for="item in footerTable"
                                        :key="item.name"
                                        class="md-table-head"
                                >
                                    <div
                                            class="md-table-head-container md-ripple md-disabled"
                                    >
                                        <div class="md-table-head-label">{{ item }}</div>
                                    </div>
                                </th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>

                </md-card-content>
                <md-card-actions md-alignment="space-between">
                    <div class>
                        <p class="card-category">
                            {{
                            $t('label.showing_from_to_of_entries', {
                              from: to === 0 ? 0 : from + 1,
                              to: to,
                              total
                            })
                            }}
                        </p>
                    </div>
                    <pagination
                            class="pagination-no-border pagination-success"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="total"
                    ></pagination>
                </md-card-actions>
            </md-card>
        </div>
    </div>

</template>

<script>

import {Pagination} from '@/components'
import XLSX from "xlsx"
// import {SlideYDownTransition} from 'vue2-transitions'
// import Fuse from 'fuse.js'

export default {
    name: "plans-work-list",
    components: {
        Pagination
    },
    data() {
        return {
            pid: this.$route.params.pid,
            workList: {headers: [], rows: []},
            isPlanWorksDataLoading: false,
            selected: [],
            selectedRows: [],
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            currentSort: 'key',
            currentSortOrder: 'asc',
            searchQuery: '',
            propsToSearch: ['key'],
            searchedData: [],
            fuseSearch: null,
            footerTable: [],
            reportName: "planning",
            isPlanExportDataLoading: false,
            checkAllSelected: true,
            boolean: true,
            headers: ['bridge_id', 'year', 'element', 'element_bci', 'work_type', 'work_name', 'quantity', 'unit_cost', 'total_cost', 'description']
        }
    },
    filters: {
        toDate: function (value) {
            return value.substring(0, 10)
        }
    },
    async beforeRouteUpdate(to, from, next) {
        // просто используйте `this`
        const {domain} = to.params
        await this.refreshListData(domain)
        next()
    },
    async created() {
        const {domain} = this.$route.params
        // this.$store.dispatch("LOAD_PLAN_WORKS", ).then(res => {
        //   this.workList = [...res]
        // })
        await this.refreshListData(domain)
    },
    methods: {
        onSelectAllChanged() {
            const items = this.isAllSelected ? [] : this.exportedData
            this.selectedRows = items
        },
        onSelect(items) {
            const data = {type: "none", work_ids: []}
            const newSelected = items.map(item => item[this.indexFieldId])
            const addedItems = newSelected.filter(x => !this.selected.includes(x))
            const deletedItems = this.selected.filter(x => !newSelected.includes(x))

            if (addedItems.length > 0) {
                data.type = "add"
                data.work_ids = addedItems
            } else {
                data.type = "del"
                data.work_ids = deletedItems
            }
            if (data.work_ids.length > 0) {
                this.$store.dispatch("PLANS_CHANGE_WORK_SELECTED", {plan_id: this.pid, data})
            }
            this.selected = [...newSelected]
        },
        isCostOverhead(cost, budget) {
            return cost > budget
        },

        async refreshListData() {
            this.isPlanWorksDataLoading = true
            try {


                const list = await this.$store.dispatch("LOAD_PLAN_WORKS", this.pid)
                this.workList.headers = [...list.headers]
                this.workList.rows = Array.isArray(list.rows) ? [...list.rows] : []
                const selectedFieldIndex = this.workList.headers.findIndex(header => (header === "selected" || header === "userselected"))
                this.selectedRows = this.workList.rows.filter(row => row[selectedFieldIndex] === true)
                this.selected = this.selectedRows.map(row => row[this.indexFieldId])
            } catch (err) {
                throw err
            } finally {
                this.isPlanWorksDataLoading = false
            }
        },
        customSort(value) {
            const fieldInd = this.workList.headers.findIndex(header => header === this.currentSort)

            if (!~fieldInd) {
                return
            }

            return value.sort((a, b) => {
                if (this.currentSortOrder === 'asc') {
                    return a[fieldInd]
                        .toString()
                        .localeCompare(b[fieldInd].toString(), undefined, {numeric: true})
                }
                return b[fieldInd]
                    .toString()
                    .localeCompare(a[fieldInd].toString(), undefined, {numeric: true})
            })
        },
        onExport() {
            this.isPlanExportDataLoading = true
            setTimeout(() => {
                this.downloadExport()
            }, 10)
            //
        },

        downloadExport() {
            const headers = this.workList.headers.map((header) =>
                this.$t(`translate.${header}`)
            )
            const export_data = [headers, ...this.exportedData]
            const today = new Date().toJSON().slice(0, 10)
            const worksheet = XLSX.utils.aoa_to_sheet(export_data)
            const new_workbook = XLSX.utils.book_new()
            const filter_name = !this.searchQuery
                ? ''
                : `_filter(${this.searchQuery})`

            XLSX.utils.book_append_sheet(
                new_workbook,
                worksheet,
                `Report${filter_name}`
            )
            XLSX.writeFile(
                new_workbook,
                `export_${this.$t(this.reportName)}_${today}.xlsx`
            )
            this.isPlanExportDataLoading = false
        }
    },
    computed: {
        isAllSelected() {
            return this.exportedData.length > 0 && this.selected.length === this.exportedData.length
        },
        queriedData() {
            const result = this.searchQuery
                ? this.searchedData
                    ? this.searchedData
                    : []
                : this.tableData
            return result.slice(this.from, this.to)
        },
        exportedData() {
            const result = !this.searchQuery
                ? this.tableData
                : this.searchedData
                    ? this.searchedData
                    : []
            return result
        },
        to() {
            let highBound = this.from + this.pagination.perPage
            if (this.total < highBound) {
                highBound = this.total
            }
            return highBound
        }
        ,
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1)
        }
        ,
        total() {
            return this.searchQuery ? this.searchedData.length : this.tableData.length
        }
        ,
        tableData() {
            return this.workList.rows
        }
        ,
        indexFieldId() {
            return this.workList.headers.findIndex(header => header === "id")
        }

    },
    watch: {
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        searchQuery(value) {
            let result = this.tableData
            const regex = new RegExp(`${value}`, 'i')
            if (value !== '' && value.length > 3) {
                result = this.tableData.filter((row) => {
                    return row.reduce((incl, elem) => incl || regex.test(elem), false)
                })
            }
            this.searchedData = result
        }
    }
}

</script>

<style lang="scss" >
@import '@/assets/scss/md/_variables.scss';

.cost-overhead {
  color: red;
}

.wrapper {
  //height: unset !important;
}

.cost-good {
  color: green;
}

.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.md-table-row.md-selected {
  background-color: #e0f5da !important;
}

.report-export-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 25px;
  color: $gray-color;
  cursor: pointer;
}

.card-icon {
  z-index: 10;
}

.data-load-progress {
  z-index: 9;
  position: absolute;
  width: 100%;
  /* height: 100%; */
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .progress-bar {
    width: 100%;
  }
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;

  &:hover {
    //white-space: pre-wrap;
    //overflow: unset;
  }
}

/* Define an animation behavior */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
  color: $brand-primary !important;
}


//.table-shopping /deep/ .md-table-head:nth-child(5),
//.table-shopping /deep/ .md-table-head:nth-child(7),
//.table-shopping /deep/ .md-table-head:nth-child(6) {
//  text-align: right;
//}

//.table-shopping  .md-table-head:nth-child(5),
//.table-shopping  .md-table-head:nth-child(7),
//.table-shopping  .md-table-head:nth-child(6) {
//  text-align: right;
//}
</style>
